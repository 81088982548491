.app-option {
  @apply rounded-md;
  @apply text-base;

  @apply font-normal;
  @apply p-2;
  min-width: 220px;
  cursor: pointer;

  &:hover {
    background: #0017FF66;//linear-gradient(315deg, #6D54DE66 0%, #9C58EF66 100%);

    //@apply text-white;
  }

  &--selected {
    background: #0017FF;//linear-gradient(315deg, #0017FF 0%, #0017FF 100%) !important;
    color: white !important;
  }
}